<template>
  <div class="aali_tm_section">
    <div class="aali_tm_resume">
      <div class="content">
        <div class="container">
          <div class="resume_in">
            <div
              class="aali_tm_main_title"
              data-text-align="left"
              data-color="dark"
            >
              <span>Know More</span>
              <h3>My Resume</h3>
              <p>
                I enjoy every step of the design process, from discussion and
                collaboration to concept and execution
              </p>
            </div>
            <div class="content_inner">
              <ul>
                <li class="wow fadeInLeft" data-wow-duration="1s">
                  <h3 class="main_title"><span>Education</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Management</h3>
                            <span>BINUS University</span>
                          </div>
                          <div class="right">
                            <span>2021 - Now</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Description
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="edu"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Computer Science</h3>
                            <span>Gadjah Mada University</span>
                          </div>
                          <div class="right">
                            <span>2017 - 2020</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Description
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="wow fadeInRight" data-wow-duration="1s">
                  <h3 class="main_title"><span>Experience</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Data Engineer</h3>
                            <span>PT GITS Indonesia</span>
                          </div>
                          <div class="right">
                            <span>2022 - Now</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Descriptions
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>DevOps Engineer</h3>
                            <span>PT GITS Indonesia</span>
                          </div>
                          <div class="right">
                            <span>2020 - 2022</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Descriptions
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Back End Developer</h3>
                            <span>Bytechnology Yogyakarta</span>
                          </div>
                          <div class="right">
                            <span>2019</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Descriptions
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li class="wow fadeInRight" data-wow-duration="1s">
                  <h3 class="main_title"><span>Certification</span></h3>
                  <ul class="list">
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Specialist in Containers and Kubernetes</h3>
                            <span>Red Hat Certified</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Descriptions
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Professional Cloud Architect</h3>
                            <span>Google Cloud Certified</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Descriptions
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <span class="icon" v-html="exp"></span>
                        <div class="info">
                          <div class="left">
                            <h3>Associate Cloud Engineer</h3>
                            <span>Google Cloud Certified</span>
                          </div>
                        </div>
                        <div class="text">
                          <p>
                            Descriptions
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="media" data-background-style="video">
        <!-- Background Styles: "video" and "image" // Also you can use any youtube, vimeo, and local videos -->
        <div
          class="video jarallax"
          data-speed="0"
          data-jarallax-video="https://vimeo.com/337293658"
        ></div>
        <div
          class="image jarallax"
          data-speed="0"
          data-img-url="img/about/2.jpg"
        ></div>
        <span
          class="square moving_effect"
          data-direction="y"
          data-reverse="yes"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { jarallaxContent } from "../utils/utils";
import { edu, exp } from "./svgImage";

export default {
  name: "Resume",
  data() {
    return {
      edu,
      exp,
    };
  },
  mounted() {
    jarallaxContent();
  },
};
</script>
