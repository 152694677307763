<template>
  <fragment>
    <div class="aali_tm_intro">
      <div class="short_info">
        <img src="img/logo/dark.png" alt="" />
        <h3>Personal Website</h3>
      </div>
      <!-- <span class="intro_line"></span>
      <span class="intro_line_2"></span>
      <span class="intro_line_3"></span> -->
      <div class="short_info" style="margin-top:20em">
        <h1>Coming Soon!</h1>
      </div>
    </div>
  </fragment>
</template>

<script>
export default {
  name: "ComingSoon",
  mounted() {
    document.querySelector("body").classList.add("intro");
  },
};
</script>
