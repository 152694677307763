<template>
  <fragment>
    <div>
      <div class="mouse-cursor cursor-outer"></div>
      <div class="mouse-cursor cursor-inner"></div>
    </div>
  </fragment>
</template>

<script>
export default {
  name: "Mouse",
  mounted() {
    this.isMobile = window.innerWidth < 768;
    console.log(this.isMobile);
  }
};
</script>
