import Vue from "vue";
import VueRouter from "vue-router";
import Dark from "./views/Index-dark.vue";
import Index from "./views/Index.vue";
import Intro from "./views/Intro.vue";
import ComingSoon from "./views/ComingSoon.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/intro",
    name: "Intro",
    component: Intro,
  },
  {
    path: "/tmp",
    name: "Index",
    component: Index,
  },
  {
    path: "/index-dark",
    name: "Dark",
    component: Dark,
  },
  {
    path: "/",
    name: "ComingSoon",
    component: ComingSoon
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
